import React, { FunctionComponent, useRef, useState } from 'react';
import { SiteLogo } from './logo/siteLogo';
import { Parallax } from 'react-scroll-parallax/cjs';
import { isMobile, isTablet } from 'react-device-detect';
import { ConditionalWrapper } from '../conditionalWrapper';

export const Header: FunctionComponent = () => {
  const videoEl = useRef<HTMLVideoElement>();
  const [videoClass, setVideoClass] = useState('');
  const videos = [
    '1.mp4',
    '5.mp4',
    '9.mp4',
    '7.mp4',
    '8.mp4'
  ];

  const selectedVideoIndex = Math.floor(Math.random() * videos.length);
  const selectedVideo = videos[selectedVideoIndex];
  const showVideo = () => {
    setVideoClass('shown');
    videoEl.current?.play();
  };
  const className = `header ${isTablet ? 'tablet' : ''}`;

  return (
    <ConditionalWrapper
      condition={!isMobile}
      whenTrue={(children: any) => (
        <Parallax className="header-container" tagOuter="figure">
          {children}
        </Parallax>
      )}
      whenFalse={(children: any) => <figure className={'header-container'}>{children}</figure>}
    >
      <div className={className}>
        <SiteLogo />
        <div className={'hero-image'}>
          <video
            ref={videoEl}
            width={'100%'}
            height={'100%'}
            autoPlay
            playsInline
            muted={true}
            loop
            className={`fullscreen-video ${videoClass}`}
            onCanPlay={showVideo}
          >
            <source src={`videos/${selectedVideo}`} type="video/mp4" />
          </video>
        </div>
      </div>
    </ConditionalWrapper>
  );
};
