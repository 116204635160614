import React, { FunctionComponent, useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';
import { navigate } from 'gatsby';

type SiteLogoProps = {
  classNames?: string;
};

export const SiteLogo: FunctionComponent<SiteLogoProps> = ({ classNames }) => {
  const [shownClassName, setShowClassName] = useState('caret');
  const [containerClassName, setContainerClassName] = useState(`site-logo__container ${classNames}`);

  useEffect(() => {
    setTimeout(() => setContainerClassName(`site-logo__container active ${classNames}`), 500);
  });

  function showCaret() {
    setShowClassName('caret shown');
    document.getElementsByClassName('Typewriter__cursor')[0].classList.add('hidden');
  }

  function goHome() {
    navigate('/');
  }

  return (
    <div className={containerClassName} onClick={goHome}>
      <div className={'site-logo'}>
        <Typewriter
          options={{}}
          onInit={typewriter =>
            typewriter
              .typeString('Fabienne')
              .typeString('<br/>')
              .typeString('Herion')
              .pauseFor(1000)
              .callFunction(() => showCaret())
              .start()
          }
        />
        <div className={shownClassName}>¶</div>
      </div>
    </div>
  );
};
