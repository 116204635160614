import React, { FunctionComponent } from 'react';
import { Parallax } from 'react-scroll-parallax/cjs';
import { isMobile, isTablet } from 'react-device-detect';
import { ConditionalWrapper } from '../conditionalWrapper';

export const HeaderBackground: FunctionComponent = ({}) => {
  const className = `header-background ${isTablet ? 'tablet' : ''}`;

  return (
    <ConditionalWrapper
      condition={!isMobile}
      whenTrue={(children: any) => (
        <Parallax className="header-background-container" y={[7, -20]} tagOuter="figure">
          {children}
        </Parallax>
      )}
      whenFalse={(children: any) => <figure className={'header-background-container'}>{children}</figure>}
    >
      <div className={className} />
      <div className={'white'} />
    </ConditionalWrapper>
  );
};
