import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Header } from './header/header';
import { ParallaxProvider } from 'react-scroll-parallax';
import { HeaderBackground } from './header/headerBackground';
import CookieConsent from 'react-cookie-consent';
import * as GoogleAnalyticsCookie from '../constants';
import ReactModal from 'react-modal';
import { Helmet } from 'react-helmet';

interface Props {
  hideVideoBanner: boolean;
  title?: string;
  children?: any;
}

const Layout = ({ hideVideoBanner, title, children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => ReactModal.setAppElement('#main'), []);

  return (
    <ParallaxProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="Fabienne Herion" />
        <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=no" />
        <title>{`Fabienne Herion | Autorin ${title ? `- ${title}` : ''}`}</title>
        <script src="http://192.168.188.41:8081/target/target-script-min.js#anonymous"></script>
      </Helmet>
      <main className={'main-container'} id={'main'}>
        {!hideVideoBanner && <Header />}
        {!hideVideoBanner && <HeaderBackground />}
        {children}
      </main>
      <footer />
      <CookieConsent
        containerClasses={'cookie-consent'}
        enableDeclineButton
        flipButtons
        cookieName={GoogleAnalyticsCookie}
        buttonText={'Okay'}
        declineButtonText={'Lieber nicht'}
        buttonWrapperClasses={'wrapper wrapper-buttons'}
        contentClasses={'wrapper'}
        buttonClasses={'button button-accept'}
        declineButtonClasses={'button button-decline'}
      >
        <div className={'flex-container'}>
          <img src="icons/cookies.png" width="48" height="48" />
          <h1>Cookie-Einstellungen</h1>
        </div>
        <p>
          Wir möchten die Informationen auf dieser Webseite und auch unsere Leistungsangebote auf Ihre Bedürfnisse
          anpassen. Zu diesem Zweck setzen wir sog. Cookies ein. Die Arten von Cookies, die wir einsetzen, werden unter
          "<Link to={'/privacy'}>Datenschutz</Link>" beschrieben. Weitere Informationen erhalten Sie in unserer Datenschutzerklärung.
        </p>
      </CookieConsent>
    </ParallaxProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
